import { useEffect, useState } from 'react'
import { useQuery, useInfiniteQuery } from 'react-query'
import API from '.'
import { serializeQuery } from './utils'
import { SALES_ROLE } from '../../../server/constants/roles'
import { useAuth } from '../hooks/context/AuthContext'
import { showStockOnlyForSales } from '../../../shared/config/regional-configs'
import { usePostTrack } from './analytics'
import _ from 'lodash'

const ENDPOINT = '/api/batteries'
const QUERY_KEY = 'batteries'

export const useGetBatteries = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const useGetBatteryBrands = (query = {}, config = {}) =>
  useQuery(
    ['battery_brands', 'brand-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/brands`, query))).data,
    config
  )

export const useGetBatteriesFrontEnd = (
  page = 1,
  limit = 20,
  sort = 'brandAsc',
  hasSalesDealer = false,
  filters = {},
  query = {},
  config = {}
) => {
  const { user, country } = useAuth()

  const getInStockOnly = () =>
    Number(
      filters.inStockOnly?.[0] ??
        (hasSalesDealer || showStockOnlyForSales[country] || user?.role !== SALES_ROLE)
    )

  limit = user.role !== SALES_ROLE ? 20 : 100

  const batteries = useGetBatteries(
    { ...filters, ...query, inStockOnly: getInStockOnly(), page, limit, sort },
    config
  )

  const [searchResults, setSearchResults] = useState({})
  const [filtered, setFiltered] = useState(batteries)
  const [paginated, setPaginated] = useState(batteries)

  const { mutateAsync: track } = usePostTrack()

  const filterResults = (data = [], filters = {}) =>
    !Array.isArray(data)
      ? []
      : data?.map((item) => ({
          id: item.recid,
          ...item,
        }))

  useEffect(() => {
    setFiltered(() => {
      const newFiltered = { ...batteries }
      if (newFiltered?.data) {
        let docs = filterResults(newFiltered?.data?.data || [], filters)

        newFiltered.data = {
          docs,
          pagination: newFiltered?.data?.pagination,
          totalDocs: newFiltered?.data?.pagination.length,
          inStockOnly: getInStockOnly(),
        }
      }

      setSearchResults(() => {
        const newFilters = _.pickBy(filters, _.identity)
        const resultFilters = {}
        for (const [key, value] of Object.entries(newFilters)) {
          if (!value || value?.length === 0) {
            continue
          }
          let arr = value || []
          if (!Array.isArray(arr)) {
            arr = [arr]
          }
          arr.sort()
          resultFilters[key] = arr
        }
        return { filters: resultFilters, count: newFiltered?.data?.totalDocs }
      })
      return newFiltered
    })
  }, [filters, sort, batteries.status, batteries.isFetching])

  useEffect(() => {
    setPaginated(() => {
      const newPaginated = { ...filtered }
      if (filtered.isSuccess) {
        newPaginated.data = {
          ...filtered.data,
          docs: filtered.data?.docs || [],
          totalPages: filtered.data?.pagination?.total,
          page,
          limit,
        }
      }
      return newPaginated
    })
  }, [filtered, page, limit])

  useEffect(() => {
    if (filtered?.isSuccess && Object.keys(searchResults?.filters || {})?.length > 0) {
      track({
        event: 'Battery Search Results',
        props: searchResults,
      })
    }
  }, [JSON.stringify(searchResults), filtered?.isSuccess])

  return [paginated, filtered]
}

export const useGetBatteryQty = (battery, config = {}) => {
  if (!battery) return

  const { recid = '', site } = battery

  const params = {
    recid,
    site,
  }

  const query = new URLSearchParams(params).toString()

  const path = `${ENDPOINT}/qty?${query}`

  return useQuery([QUERY_KEY, 'qty', query], async () => (await API.get(path)).data, config)
}

export const useGetBatteryModels = (query = {}, config = {}) =>
  useQuery(
    ['battery_models', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/models`, query))).data,
    config
  )

export const useGetBatteryNames = (query = {}, config = {}) =>
  useQuery(
    ['battery_names', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/names`, query))).data,
    config
  )
