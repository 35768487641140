import React from 'react'

const KeepStockContext = React.createContext({
  items: {},
  onChange: (item, qty) => {},
  reset: () => {},
  count: 0,
})

export default KeepStockContext

export function useKeepStock() {
  return React.useContext(KeepStockContext)
}
