import { useQuery, useMutation } from 'react-query'
import API from '.'
import { useGetLubricantQty } from './lubricants'
import { useGetBrakeQty } from './brakes'
import { useGetBatteryQty } from './batteries'
import { useGetTyreQty } from './tyres'
import { serializeQuery } from './utils'

const ENDPOINT = '/api/cart'
const QUERY_KEY = 'cart'

export const useGetCart = (config = {}) =>
  useQuery([QUERY_KEY, 'detail'], async () => (await API.get(ENDPOINT)).data, config)

export const usePostCart = (config = {}) =>
  useMutation((newData) => API.post(ENDPOINT, newData), config)

export const usePatchCart = (config = {}) =>
  useMutation((updatedData) => API.patch(ENDPOINT, updatedData), config)

export const usePatchCartSalesDealer = (config = {}) =>
  useMutation((updatedData) => API.patch(`${ENDPOINT}/customer`, updatedData), config)

export const useDeleteCart = (config = {}) =>
  useMutation((id) => API.delete(`${ENDPOINT}/${id}`), config)

export const useGetStockQuantity = (item, config) => {
  switch (item?.productType) {
    case 'brake':
      return useGetBrakeQty(item?.product, config)
    case 'lubricant':
      return useGetLubricantQty(item?.product, config)
    case 'battery':
      return useGetBatteryQty(item?.product, config)
    case 'tyre':
    default:
      return useGetTyreQty(item?.product, config)
  }
}
