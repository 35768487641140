import React, { useReducer } from 'react'
import KeepStockContext from '../KeepStockContext'
import { MALAYSIA } from 'shared/constants/countries-map'

const UPDATE_KEEP_STOCK = 'UPDATE_KEEP_STOCK'
const RESET_KEEP_STOCK = 'RESET_KEEP_STOCKS'

export const getKeepStockItemKey = (item, country) => {
  const { itemRef, product: { site } = {}, unitPrice } = item
  if (!(itemRef && site && !isNaN(unitPrice)) && country !== MALAYSIA) {
    return
  }
  if (country === MALAYSIA) {
    return itemRef
  }
  return `${itemRef},${site},${unitPrice}`
}

const keepStockReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_KEEP_STOCK:
      const newState = { ...state, ...action.payload }
      for (const key in newState) {
        if (newState[key]?.incrementToShip === 0) {
          delete newState[key]
        }
      }
      return newState
    case RESET_KEEP_STOCK:
      return {}
    default:
      return state
  }
}

const KeepStockProvider = ({ children }) => {
  const [items, dispatch] = useReducer(keepStockReducer, {})

  const onChange = (item, qty, country) => {
    if (!item || isNaN(qty) || qty < 0) return

    const key = getKeepStockItemKey(item, country)
    const { quantity, quantityToShip, incrementToShip: oldIncrementToShip, shippedQuantity } = item
    let incrementToShip = Math.max(
      0,
      Math.min(Number(qty), quantity - quantityToShip - oldIncrementToShip - shippedQuantity)
    )
    const payload = {
      [key]: {
        item,
        incrementToShip,
      },
    }
    dispatch({
      type: UPDATE_KEEP_STOCK,
      payload,
    })
    return payload
  }

  const reset = () => {
    dispatch({
      type: RESET_KEEP_STOCK,
    })
  }

  return (
    <KeepStockContext.Provider
      value={{
        items,
        onChange,
        reset,
        count: Object.values(items).reduce((acc, curr) => (acc += curr?.incrementToShip), 0),
      }}
    >
      {children}
    </KeepStockContext.Provider>
  )
}

export default KeepStockProvider
