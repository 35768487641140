import { useEffect, useState } from 'react'
import { useQuery, useMutation, useInfiniteQuery } from 'react-query'
import API from '.'
import { serializeQuery } from './utils'
import { getTyreId, getTyreSize } from '../../client/utils/helpers/tyreHelpers'
import { sortNumbers, sortSites, sortStrings, sortYears } from '../utils/sort'
import { HTA_DEALER_ROLE, SALES_ROLE } from '../../../server/constants/roles'
import { useAuth } from '../hooks/context/AuthContext'
import { MALAYSIA, queryOrigins } from '../../../shared/constants/countries-map'
import { showStockOnlyForSales } from '../../../shared/config/regional-configs'
import { SITES_MAP } from '../../../shared/constants/sites-map'
import { usePostTrack } from './analytics'
import _ from 'lodash'

const ENDPOINT = '/api/tyres'
const QUERY_KEY = 'tyres'

export const useGetTyres = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'list', query],
    async () => (await API.get(serializeQuery(ENDPOINT, query))).data,
    config
  )

export const useGetTyreSizes = (query = {}, config = {}) =>
  useQuery(
    ['tyre_sizes', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/sizes`, query))).data,
    config
  )

export const useGetTyreBrands = (query = {}, config = {}) =>
  useQuery(
    ['tyre_brands', 'brand-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/brands`, query))).data,
    config
  )

export const useGetTyreLocals = (query = {}, config = {}) =>
  useQuery(
    ['tyre_locals', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/locals`, query))).data,
    config
  )

export const useGetTyrePatterns = (query = {}, config = {}) =>
  useQuery(
    ['tyre_patterns', 'list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/patterns`, query))).data,
    config
  )

export const useGetTyresFrontEnd = (
  page = 1,
  limit = 20,
  sort = 'brandAsc',
  sortList = {},
  filters = {},
  hasSalesDealer = false,
  query = {},
  config = {}
) => {
  const { user, country } = useAuth()

  const getInStockOnly = () =>
    Number(
      filters.inStockOnly?.[0] ??
        (showStockOnlyForSales[country] || hasSalesDealer || user?.role !== SALES_ROLE)
    )

  limit = user.role !== SALES_ROLE ? 20 : 100

  const tyres = useGetTyres(
    {
      ...query,
      ...filters,
      inStockOnly: getInStockOnly(),
      page,
      limit,
      sort,
    },
    config
  )

  const [searchResults, setSearchResults] = useState({})
  const [filtered, setFiltered] = useState({ ...tyres })
  const [paginated, setPaginated] = useState({ ...tyres })

  const { mutateAsync: track } = usePostTrack()

  const filterResults = (data = [], filters = {}) =>
    !Array.isArray(data)
      ? []
      : data?.map((tyre) => ({
          id: getTyreId(tyre),
          ...tyre,
        })) /*.filter(
            (tyre) =>
              !(filters.promotionOnly && filters.promotionOnly[0] == 1) ||
              tyre.promotions?.length > 0
          ) 
          .filter(
            (tyre) =>
              !filters.pattern ||
              !filters.pattern?.[0]?.trim() ||
              tyre.fullPattern.toLowerCase().indexOf(filters.pattern[0]?.trim().toLowerCase()) >=
                0 ||
              tyre.pattern.toLowerCase().indexOf(filters.pattern[0]?.trim().toLowerCase()) >= 0
          )
          // .filter(
          //   (tyre) =>
          //     !filters.types ||
          //     filters.types?.length === 0 ||
          //     filters.types?.indexOf(tyre.type) >= 0
          // )
          .filter(
            (tyre) =>
              ![HTA_DEALER_ROLE, SALES_ROLE].includes(user?.role) ||
              !user?.htaConfig?.useLocalNum ||
              !filters.localNums ||
              filters.localNums?.length === 0 ||
              filters.localNums?.indexOf(tyre.localNum) >= 0
          )
          .filter(
            (tyre) =>
              !filters.brands ||
              filters.brands?.length === 0 ||
              filters.brands?.indexOf(tyre.brand) >= 0
          )
          .filter(
            (tyre) =>
              !filters.origins ||
              filters.origins?.length === 0 ||
              tyre.origin
                ?.split('/')
                .reduce(
                  (acc, origin) =>
                    acc || queryOrigins(filters.origins, country)?.indexOf(origin) >= 0,
                  false
                )
          )
          .filter(
            (tyre) =>
              !filters.sites ||
              filters.sites?.length === 0 ||
              filters.sites?.indexOf(tyre.site) >= 0 //||
            // filters.sites?.filter(
            //   (site) => Object.keys(tyre.subSitesQty || {})?.indexOf(site) >= 0
            // ).length > 0 ||
            // (Object.keys(tyre.subSitesQty || {}).length === 0 &&
            //   Object.entries(SITES_MAP[country]?.mergedSitesDict || {}).reduce(
            //     (acc, [key, value]) => {
            //       return (
            //         acc ||
            //         (key === tyre.site &&
            //           filters.sites?.filter((site) => Object.keys(value)?.indexOf(site) >= 0)
            //             .length > 0)
            //       )
            //     },
            //     false
            //   ))
          )
          .filter((tyre) => {
            let result = true
            if (filters.fromPrice) {
              result = result && tyre.price >= filters.fromPrice
            }
            if (filters.toPrice) {
              result = result && tyre.price <= filters.toPrice
            }
            return result
          })
          .filter((tyre) => !(inStockOnly == 1) || tyre.quantity > 0)
          .filter(
            (tyre) =>
              !filters.sizes?.filter(Boolean).length ||
              filters.sizes.includes(
                getTyreSize({
                  width: tyre.width,
                  profile: tyre.profile,
                  rimSize: tyre.rimSize,
                })?.replace(/\//g, '')
              )
          )*/

  useEffect(() => {
    setFiltered(() => {
      const newFiltered = { ...tyres }

      if (newFiltered?.data) {
        let docs = filterResults(newFiltered?.data.data || [], filters) || []

        newFiltered.data = {
          docs,
          pagination: newFiltered?.data?.pagination,
          totalDocs: newFiltered?.data?.pagination?.length,
          inStockOnly: getInStockOnly(),
        }
      }

      setSearchResults(() => {
        const newFilters = _.pickBy(filters, _.identity)
        const resultFilters = {}
        for (const [key, value] of Object.entries(newFilters)) {
          if (!value || value?.length === 0) {
            continue
          }
          let arr = value || []
          if (!Array.isArray(arr)) {
            arr = [arr]
          }
          arr.sort()
          resultFilters[key] = arr
        }
        return { filters: resultFilters, count: newFiltered?.data?.totalDocs }
      })

      return newFiltered
    })
  }, [filters, sort, tyres.status, tyres.isFetching])

  useEffect(() => {
    setPaginated(() => {
      const newPaginated = { ...filtered }

      if (filtered.isSuccess) {
        newPaginated.data = {
          ...filtered.data,
          docs: filtered.data?.docs || [],
          totalPages: filtered.data?.pagination?.total,
          page,
          limit,
        }
      }

      return newPaginated
    })
  }, [filtered, page, limit])

  useEffect(() => {
    if (
      filtered?.isSuccess &&
      Object.keys(searchResults?.filters || {})?.length > 0 &&
      searchResults?.filters?.sizes?.length > 0
    ) {
      track({
        event: 'Tyre Search Results',
        props: searchResults,
      })
    }
  }, [JSON.stringify(searchResults), filtered?.isSuccess])

  return [paginated, filtered]
}

export const useGetTyresInfinite = (query = {}, config = {}) =>
  useInfiniteQuery(
    QUERY_KEY,
    async ({ pageParam = 1 }) =>
      (await API.get(serializeQuery(ENDPOINT, { page: pageParam, ...query }))).data,
    {
      getPreviousPageParam: (firstPage, pages) => firstPage.prevPage,
      getNextPageParam: (lastPage, pages) => lastPage.nextPage,
      /*select: (data) => {
        console.log(data.pages, data.pageParams)
        return {
          pages: data.pages.slice(1),
          pageParams: data.pageParams.slice(1),
        }
      },*/
      ...config,
    }
  )

export const useGetTyreClaims = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'claims', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/claims`, query))).data,
    config
  )

export const useGetTyreQty = (tyre, config = {}) => {
  if (!tyre) return

  const {
    itemRef = '',
    brand,
    pattern,
    width,
    profile,
    rimSize,
    speedRating,
    loadIndex,
    homologation = '',
    site,
    subSites,
    origin,
    batch,
  } = tyre

  const params = {
    itemRef,
    brand,
    pattern,
    width,
    profile,
    rimSize,
    speedRating,
    loadIndex,
    homologation,
    site: subSites?.join(',') || site,
    origin,
    batch,
  }

  const query = new URLSearchParams(params).toString()

  const path = `${ENDPOINT}/qty?${query}`

  return useQuery([QUERY_KEY, 'qty', query], async () => (await API.get(path)).data, config)
}

export const useGetBmwLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'bmw-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/bmw-locals`, query))).data,
    config
  )

export const useCreateBmwLocals = (config = {}) =>
  useMutation(async (newData) => await API.post(`${ENDPOINT}/bmw-locals`, newData), config)

export const useUpdateBmwLocals = (config = {}) =>
  useMutation(async (newData) => await API.patch(`${ENDPOINT}/bmw-locals`, newData), config)

export const useDeleteBmwLocals = (config = {}) =>
  useMutation(async (newData) => {
    return await API.delete(`${ENDPOINT}/bmw-locals`, { data: newData })
  }, config)

export const useExistsBmwLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'exists', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/bmw-locals/exists`, query))).data,
    config
  )

export const useGetJlrLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'jlr-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/jlr-locals`, query))).data,
    config
  )

export const useCreateJlrLocals = (config = {}) =>
  useMutation(async (newData) => await API.post(`${ENDPOINT}/jlr-locals`, newData), config)

export const useUpdateJlrLocals = (config = {}) =>
  useMutation(async (newData) => await API.patch(`${ENDPOINT}/jlr-locals`, newData), config)

export const useDeleteJlrLocals = (config = {}) =>
  useMutation(async (newData) => {
    return await API.delete(`${ENDPOINT}/jlr-locals`, { data: newData })
  }, config)

export const useExistsJlrLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'exists', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/jlr-locals/exists`, query))).data,
    config
  )

export const useGetMBLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'mb-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/mb-locals`, query))).data,
    config
  )

export const useCreateMBLocals = (config = {}) =>
  useMutation(async (newData) => await API.post(`${ENDPOINT}/mb-locals`, newData), config)

export const useUpdateMBLocals = (config = {}) =>
  useMutation(async (newData) => await API.patch(`${ENDPOINT}/mb-locals`, newData), config)

export const useDeleteMBLocals = (config = {}) =>
  useMutation(async (newData) => {
    return await API.delete(`${ENDPOINT}/mb-locals`, { data: newData })
  }, config)

export const useExistsMBLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'exists', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/mb-locals/exists`, query))).data,
    config
  )

export const useGetVwLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'vw-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/vw-locals`, query))).data,
    config
  )

export const useCreateVwLocals = (config = {}) =>
  useMutation(async (newData) => await API.post(`${ENDPOINT}/vw-locals`, newData), config)

export const useUpdateVwLocals = (config = {}) =>
  useMutation(async (newData) => await API.patch(`${ENDPOINT}/vw-locals`, newData), config)

export const useDeleteVwLocals = (config = {}) =>
  useMutation(async (newData) => {
    return await API.delete(`${ENDPOINT}/vw-locals`, { data: newData })
  }, config)

export const useExistsVwLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'exists', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/vw-locals/exists`, query))).data,
    config
  )

export const useGetBydLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'byd-list', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/byd-locals`, query))).data,
    config
  )
export const useCreateBydLocals = (config = {}) =>
  useMutation(async (newData) => await API.post(`${ENDPOINT}/byd-locals`, newData), config)

export const useUpdateBydLocals = (config = {}) =>
  useMutation(async (newData) => await API.patch(`${ENDPOINT}/byd-locals`, newData), config)

export const useDeleteBydLocals = (config = {}) =>
  useMutation(async (newData) => {
    return await API.delete(`${ENDPOINT}/byd-locals`, { data: newData })
  }, config)

export const useExistsBydLocals = (query = {}, config = {}) =>
  useQuery(
    [QUERY_KEY, 'exists', query],
    async () => (await API.get(serializeQuery(`${ENDPOINT}/byd-locals/exists`, query))).data,
    config
  )
