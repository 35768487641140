import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export const useRouteProps = (routes) => {
  const { pathname } = useLocation()

  const [path, setPath] = useState('')
  const [depth, setDepth] = useState(0)

  useEffect(() => {
    const pathHierarchy = pathname.split('/').filter(Boolean)
    const newPath = pathHierarchy[0]
    if (routes[newPath]) {
      setDepth(pathHierarchy.length - 1)
      if (path !== newPath) {
        setPath(newPath)
      }
    }
  }, [pathname])

  return [path, routes[path], depth]
}
