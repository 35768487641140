import React from 'react'
import { makeStyles } from '@material-ui/core'
import { MALAYSIA, SINGAPORE } from 'shared/constants/countries-map'
import { useAuth } from '@src/shared/hooks/context/AuthContext'
import { HTA_DEALER_ROLE } from '../../../../server/constants/roles'

const useStyles = makeStyles((theme) => ({
  automarketlogo: {
    // paddingLeft: '2px',
    height: 60, // Default height
    [theme.breakpoints.up('md')]: {
      height: 80, // Height for large and up screens
    },
  },
  tyrehublogo: {
    height: 30,
    [theme.breakpoints.up('md')]: {
      height: 40,
    },
  },
}))

const HeaderLogo = ({ country }) => {
  const { user } = useAuth()
  const classes = useStyles()
  let src = '/images/icons/automarket'
  let logoClass = classes.automarketlogo

  if (user) {
    if (user.role === HTA_DEALER_ROLE) {
      src = '/images/icons/icon-120'
      logoClass = classes.tyrehublogo
    } else if (country === SINGAPORE) {
      src = '/images/ht-logo/sg/hocktyre-64'
      logoClass = classes.tyrehublogo
    }
  }

  return <img className={logoClass} src={`${src}.png`} alt='logo' />
}

export default HeaderLogo
