import { makeStyles } from '@material-ui/core'
import red from '@material-ui/core/colors/red'

const useNavBarStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'black',
    width: '100vw',
    height: '44px',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: '0',
    paddingLeft: '5%',
  },
  button: {
    color: 'white',
    height: '44px',
    minWidth: '180px',
    fontWeight: 'bold',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  menu: {
    borderRadius: '0',
    '& .MuiPaper-root': {
      backgroundColor: 'black',
      borderRadius: '0',
    },
  },
  menuItem: {
    borderRadius: '0',
    backgroundColor: 'black',
    color: 'white',
    width: '180px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItem: {
    fontWeight: 'bold',
  },
}))

export default useNavBarStyles
