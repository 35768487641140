import React from 'react'
import { createTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import red from '@material-ui/core/colors/red'

const useGlobalTheme = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ['Montserrat'],
        },
        palette: {
          primary: {
            main: '#000000',
          },
          secondary: {
            main: '#ec1d35',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              a: {
                color: 'inherit',
                textDecoration: 'none',
              },
            },
          },
          MuiContainer: {
            root: {
              paddingBottom: 24,
              minWidth: '93%',
            },
          },
          MuiTableCell: {
            root: {
              overflow: 'hidden',
            },
          },
          MuiCard: {
            root: {
              border: '2px solid #C8C8C8',
              boxShadow: 'none',
            },
          },
          MuiGridContainer: {
            root: {
              border: '1px solid #C8C8C8',
              boxShadow: 'none',
              borderRadius: '2px',
            },
          },
          MuiSlider: {
            root: {
              color: 'secondary',
            },
          },
          MuiSectionContainer: {
            '& .MuiPaper-root': {
              border: '1px solid black',
            },
          },
          MuiBadge: {
            badge: {
              color: 'white',
              backgroundColor: '#ec1d35',
              overlap: 'rectangular',
            },
          },
          MuiCheckbox: {
            root: {
              '&$checked': {
                color: '#ec1d35',
              },
            },
            colorSecondary: {
              '&$checked': {
                color: '#ec1d35',
              },
            },
          },
          MuiSvgIcon: {
            root: {
              color: '#000000',
            },
          },
        },
      }),
    [prefersDarkMode]
  )

  return theme
}

export default useGlobalTheme
