import React from 'react'

const AuthContext = React.createContext({
  user: {},
  isAuthorized: false,
  country: '',
  updateUser: () => {},
  login: (email, password) => {},
  logout: () => {},
  hasCartAccess: false,
  canViewPrice: false,
  trialConfig: {
    isTrial: false,
    expiresAt: null,
    trialExpired: false,
    duration: '',
    warning: false,
  },
})

export default AuthContext

export function useAuth() {
  return React.useContext(AuthContext)
}
