import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const allowSentry = ENABLED_SENTRY === 't'

if (allowSentry) {
  Sentry.init({
    dsn: `https://${SENTRY_USER}@${SENTRY_PASS}.ingest.sentry.io/${SENTRY_APP}`,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    tracesSampleRate: 0.001,
    beforeSend: (event) => {
      return event
    },
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
