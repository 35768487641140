const MERCEDES = 'mercedes'
const BMW = 'bmw'
const JLR = 'jlr'
const VW = 'vw'
const BYD = 'byd'

const htaTypeDict = {
  [MERCEDES]: 'Mercedes',
  [BMW]: 'BMW',
  [JLR]: 'JLR',
  [VW]: 'Volkswagen',
  [BYD]: 'BYD',
}

module.exports = { htaTypeDict, MERCEDES, BMW, JLR, VW, BYD }
