export const serializeQuery = (endpoint, query) =>
  endpoint +
  '?' +
  Object.entries(query)
    .map((entry) =>
      entry
        .map((value) =>
          Array.isArray(value) ? value.map(encodeURIComponent).join(',') : encodeURIComponent(value)
        )
        .join('=')
    )
    .join('&')

export const createQueryKeys = (queryKey) => {
  const keys = {
    all: [queryKey],
    lists: () => [...keys.all, 'list'],
    list: (query = {}) => [...keys.lists(), query],
    details: () => [...keys.all, 'detail'],
    detail: (id) => [...keys.details(), id],
  }

  return keys
}

export const getPath = (endpoint, path = '', query = {}) => serializeQuery(endpoint + path, query)
