import React from 'react'

const CsContext = React.createContext({
  orderData: [],
  orderRefetch: () => {},
  ksOrderData: [],
  ksOrderRefetch: () => {},
  getProcessingQty: () => {},
})

export default CsContext
