import React from 'react'
import { Typography, Box, Container } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const useStyles = makeStyles((theme) => ({
  dot: {
    marginLeft: '4px',
    marginRight: '4px',
    fontSize: '8px',
    color: 'lightgray',
  },
}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <Box
      mt={4}
      px={3}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      sx={{ bottom: 0 }}
      style={{ borderTop: '1px solid #e9e9e9' }}
    >
      <Box
        mb={3}
        order={{ sm: 2, xs: 2, md: 1, lg: 1 }}
        sx={{ textAlign: { xs: 'center', md: 'left' } }}
      >
        <Box mt={3}>
          <Typography variant='body2' style={{ color: '#5a5a5a' }}>
            © {new Date().getFullYear() ?? ''} HT Tyrehub Sdn Bhd.
            <FiberManualRecordIcon className={classes.dot}></FiberManualRecordIcon>
            198101002867(68981-T)
            <FiberManualRecordIcon className={classes.dot}></FiberManualRecordIcon>
            All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
